<template>
  <div class="footer-navigation flex bg-white py-5 justify-center">
    <router-link class="footer-navigation--link text-green font-bold mx-3 hover:text-green_dark" v-for="page in links" :key="page.attributes.id" :to="page.attributes.Slug">{{ page.attributes.name }}</router-link>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import eventService from "@/services/eventService";
import qs from "qs";

export default {
  name: "FooterNav",
  data() {
    return {
      links: '',
      queryOptions: {
        sort: [],
        filters: {},
        locale: [],
        populate: '*'
      }
    }
  },
  components: {
  },
  created() {
    this.queryOptions.filters.Slug = '/footer';
    this.queryOptions.locale = [this.$root.$i18n.locale];
    this.fetchNavigation();
  },
  mounted() {
    eventService.$on('lang-switch', (lang) => {
      this.queryOptions.locale = [lang];
      this.fetchNavigation();
    });
  },
  methods: {
    fetchNavigation() {
      let query = qs.stringify(this.queryOptions);
      ApiService.get(`navigations?${query}`).then(res => {
        let data = res.data.data;
        if (data[0]) {
          this.links = data[0].attributes.seitens.data;
        }
      })
    }
  }
}
</script>
